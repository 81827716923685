import React, { useEffect, useState } from 'react';
import { Link, useParams } from 'react-router-dom';
import { API } from '../../services/axios';
import { URLS } from '../../services/urls';
import { toast } from 'react-toastify';
import ReactMarkdown from 'react-markdown';
import Preloader from '../../components/preloader/Preloader';
import { FaPaperPlane, FaTimes, FaPaperclip, FaSignOutAlt, FaCopy } from 'react-icons/fa';  
import { getUserDetails } from '../../components/utils/functions';
import { logout } from '../../services/logout';
import { useTranslation } from "react-i18next";
import { Prism as SyntaxHighlighter } from 'react-syntax-highlighter';
import { coy } from 'react-syntax-highlighter/dist/esm/styles/prism';
import { dracula } from 'react-syntax-highlighter/dist/esm/styles/prism'; // Style avec fond noir
import { CopyToClipboard } from 'react-copy-to-clipboard';

const ChatWindow = () => {
  const { t } = useTranslation();
  const { chatId } = useParams();
  const [messages, setMessages] = useState([]);
  const [newMessage, setNewMessage] = useState('');
  const [loading, setLoading] = useState(false);
  const [images, setImages] = useState([]); // Support pour plusieurs images
  const [pdfFiles, setPdfFiles] = useState([]); // Support pour plusieurs fichiers PDF
  const [previews, setPreviews] = useState([]); // Aperçus des images et PDF
  const [userEmail, setUserEmail] = useState(null);
  const [user, setUser] = useState(null);
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);

  useEffect(() => {
    const userDetails = getUserDetails();
    if (userDetails) {
      setUser(userDetails);
    }
  }, []);

  useEffect(() => {
    const user = getUserDetails();
    if (user && user.email) {
      setUserEmail(user.email);
    }
    const fetchMessages = async () => {
      setLoading(true);
      try {
        const response = await API.get(`${URLS.BASE_URL}${URLS.CHATGPT.chat.details.replace('<int:chat_id>', chatId)}`);
        if (Array.isArray(response.data)) {
          setMessages(response.data);
        } else {
          toast.error('Les données reçues ne sont pas valides.');
        }
      } catch (error) {
        toast.error('Erreur lors de la récupération des messages.');
      } finally {
        setLoading(false);
      }
    };

    fetchMessages();
  }, [chatId]);

  useEffect(() => {
    if (images.length > 0) {
      const imagePreviews = images.map(image => URL.createObjectURL(image));
      setPreviews(prevPreviews => [...prevPreviews, ...imagePreviews]);
      return () => imagePreviews.forEach(url => URL.revokeObjectURL(url)); // Nettoyage des URLs
    } else {
      setPreviews([]);
    }
  }, [images]);

  useEffect(() => {
    if (pdfFiles.length > 0) {
      const pdfPreviews = pdfFiles.map(pdf => pdf.name); // Utilisez le nom du fichier PDF pour l'aperçu
      setPreviews(prevPreviews => [...prevPreviews, ...pdfPreviews]);
    }
  }, [pdfFiles]);

  const handleSendMessage = async () => {
    if (!newMessage.trim() && images.length === 0) {
      toast.error("Veuillez taper un message ou ajouter des images avant d'envoyer.");
      return;
    }

    const newMessageObject = {
      role: 'user',
      content: newMessage,
    };

    // Mettre à jour immédiatement les messages locaux avec le nouveau message de l'utilisateur
    setMessages((prevMessages) => [...prevMessages, newMessageObject]);

    try {
      const formData = new FormData();
      formData.append('role', 'user');
      formData.append('content', newMessage);

      images.forEach((image) => {
        formData.append('image', image); // Ajouter toutes les images
      });

      setLoading(true);
      const response = await API.post(
        `${URLS.BASE_URL}${URLS.CHATGPT.chat.update.replace('<int:chat_id>', chatId)}`,
        formData
      );

      const updatedMessages = response.data.messages;

      if (Array.isArray(updatedMessages)) {
        // Ajouter uniquement les messages de l'IA, car le message utilisateur est déjà dans l'état
        setMessages((prevMessages) => [...prevMessages, ...updatedMessages.filter(msg => msg.role !== 'user')]);
      } else {
        toast.error('Réponse du serveur non valide.');
      }

      setNewMessage('');
      setImages([]); // Réinitialiser les images après envoi
    } catch (error) {
      toast.error('Erreur lors de l\'envoi du message.');
    } finally {
      setLoading(false);
    }
  };

  const handleFileChange = (e) => {
    const newFiles = [...e.target.files];
    const imageFiles = newFiles.filter(file => file.type.startsWith('image/'));
    const pdfFiles = newFiles.filter(file => file.type === 'application/pdf');

    setImages(imageFiles); // Stocker les images
    setPdfFiles(pdfFiles); // Stocker les fichiers PDF
  };

  const handleRemoveFile = (index, fileType) => {
    if (fileType === 'image') {
      const newImages = images.filter((_, i) => i !== index);
      setImages(newImages);
    } else if (fileType === 'pdf') {
      const newPdfFiles = pdfFiles.filter((_, i) => i !== index);
      setPdfFiles(newPdfFiles);
    }
  };

  // Modifiez la fonction handleKeyDown
const handleKeyDown = (e) => {
  if (e.key === 'Enter' && !e.shiftKey) {
    e.preventDefault(); // Empêche le saut de ligne
    handleSendMessage();
  }
};

  const toggleDropdown = () => {
    setIsDropdownOpen(!isDropdownOpen);
  };

  useEffect(() => {
    const scrollToBottom = () => {
      const messagesList = document.querySelector('.messages-list');
      if (messagesList) {
        messagesList.scrollTop = messagesList.scrollHeight;
      }
    };

    scrollToBottom();
  }, [messages]);

   // Fonction de copie du contenu
   const handleCopy = (text) => {
    navigator.clipboard.writeText(text);
    toast.success("Texte copié dans le presse-papiers !");
  };

  return (
    <div className="chat-window">
      <div className="chat-header">
        <div className="profile-dropdown">
          <img 
            src={"/assets/img/logo_goliat.png"} 
            alt="User Profile" 
            className="profile-image" 
            onClick={toggleDropdown} 
          />
          {isDropdownOpen && (
            <div className="dropdown-menu">
              <Link className='dropdown-item' to='/my/profil'>Mon profil</Link>
              <Link className='dropdown-item' to='/'>Tableau de bord</Link>
              <Link className='dropdown-item text-danger' to="#" onClick={() => logout(t)}>
                Se Déconnecter <FaSignOutAlt size={20} style={{ marginRight: '8px' }}/>
              </Link>
            </div>
          )}
        </div>
      </div>

      <div className="chat-body messages-list">
        {messages.map((message, index) => (
          <div key={index} className={`message ${message.role === 'user' ? 'user-message' : 'assistant-message'}`}>
            {/* Assurez-vous que le contenu est une chaîne avant de traiter */}
            {typeof message.content === 'string' && message.content.startsWith("data:image/") ? (
              <img src={message.content} alt="Chat Image" className="chat-image" />
            ) : typeof message.content === 'string' ? (
              <ReactMarkdown
                components={{
                  code({ node, inline, className, children, ...props }) {
                    const match = /language-(\w+)/.exec(className || '');
                    return !inline && match ? (
                      <div className="code-block">
                        <CopyToClipboard text={String(children).replace(/\n$/, '')}>
                          <button className="copy-btn" onClick={() => handleCopy(children)}>
                            <FaCopy />
                          </button>
                        </CopyToClipboard>
                        <SyntaxHighlighter
                          style={dracula}
                          language={match[1]}
                          PreTag="div"
                          {...props}
                        >
                          {String(children).replace(/\n$/, '')}
                        </SyntaxHighlighter>
                      </div>
                    ) : (
                      <code className={className} {...props}>
                        {children}
                      </code>
                    );
                  }
                }}
              >
                {message.content}
              </ReactMarkdown>
            ) : null}
          </div>
        ))}
        {loading && (
          <div className="preloader-wrapper">
            <Preloader />
          </div>
        )}
      </div>

      <div className="chat-footer">
        <label htmlFor="upload-input" className="upload-btn">
          <FaPaperclip />
        </label>
        <input
          id="upload-input"
          type="file"
          multiple
          onChange={handleFileChange}
          style={{ display: 'none' }}
        />
        <input
          type="text"
          value={newMessage}
          onKeyDown={handleKeyDown}
          onChange={(e) => setNewMessage(e.target.value)}
          placeholder="Chat GOLIAT-IA"
        />
        <button className="send-btn" 
        disabled={!newMessage.trim() && images.length === 0 && pdfFiles.length === 0} 
        onClick={handleSendMessage}>
          <FaPaperPlane />
        </button>
      </div>

      {previews.length > 0 && (
        <div className="file-previews">
          {previews.map((preview, index) => (
            <div key={index} className="file-preview">
              {preview.endsWith('.pdf') ? (
                <div className="pdf-preview">
                  <p>{preview}</p>
                  <button onClick={() => handleRemoveFile(index, 'pdf')}><FaTimes /></button>
                </div>
              ) : (
                <div className="image-preview">
                  <img src={preview} alt="Preview" />
                  <button onClick={() => handleRemoveFile(index, 'image')}><FaTimes /></button>
                </div>
              )}
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

export default ChatWindow;
