import React, { useEffect, useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { toast } from 'react-toastify';
import { API } from '../../services/axios';
import { URLS } from '../../services/urls';
import { getUserDetails } from '../../components/utils/functions';
import { Link } from 'react-router-dom';
import dayjs from 'dayjs';
import isToday from 'dayjs/plugin/isToday';
import isYesterday from 'dayjs/plugin/isYesterday';
import relativeTime from 'dayjs/plugin/relativeTime';
import { FaPencilAlt, FaEllipsisH, FaShare, FaArchive, FaBars, FaSignOutAlt, FaTimes } from 'react-icons/fa';  
import Preloader from '../../components/preloader/Preloader';
import DeleteButton from '../utils/deletetion'; // Importer le composant DeleteButton
import { logout } from '../../services/logout';
import { useTranslation } from "react-i18next";
import { confirmDeletion } from '../dletion';
import { LuTrash } from 'react-icons/lu';
import { FiAlignRight } from "react-icons/fi";



dayjs.extend(isToday);
dayjs.extend(isYesterday);
dayjs.extend(relativeTime);

const Sidebar = ({ isSidebarOpen, toggleSidebar }) => {
  const { t } = useTranslation();
  const [userChats, setUserChats] = useState([]);
  const [loading, setLoading] = useState(false);
  const [menuVisibleChatId, setMenuVisibleChatId] = useState(null);
  const [hoveredChatId, setHoveredChatId] = useState(null);
  const [categorizedChats, setCategorizedChats] = useState({
    today: [],
    yesterday: [],
    last7Days: [],
    last30Days: [],
    older: [],
  });

  const navigate = useNavigate();
  const [userName, setUserName] = useState(null);
  const location = useLocation(); // Utilisez useLocation pour obtenir l'URL actuelle


  useEffect(() => {
    const user = getUserDetails();
    if (user && user.first_name) {
      setUserName(user.first_name);
    }
    const fetchUserChats = async () => {
      setLoading(true);
      try {
        const user = getUserDetails();
        if (!user || !user.access_token) {
          throw new Error('User not authenticated');
        }

        const response = await API.get(URLS.CHATGPT.USERDISCUSS.list, {
          headers: {
            Authorization: `Bearer ${user.access_token}`,
          },
        });

        setUserChats(response.data.results);
      } catch (error) {
        if (error.response?.status === 401) {
          toast.error('Non autorisé. Veuillez vous reconnecter.');
        } else {
          toast.error('Erreur lors de la récupération des discussions.');
        }
      } finally {
        setLoading(false);
      }
    };

    fetchUserChats();
  }, []);

  useEffect(() => {
    categorizeChats();
  }, [userChats]);

  const categorizeChats = () => {
    const today = [];
    const yesterday = [];
    const last7Days = [];
    const last30Days = [];
    const older = [];

    userChats.forEach(chat => {
      const chatDate = dayjs(chat.updated_at);
      const now = dayjs();

      if (chatDate.isToday()) {
        today.push(chat);
      } else if (chatDate.isYesterday()) {
        yesterday.push(chat);
      } else if (chatDate.isAfter(now.subtract(7, 'day'))) {
        last7Days.push(chat);
      } else if (chatDate.isAfter(now.subtract(30, 'day'))) {
        last30Days.push(chat);
      } else {
        older.push(chat);
      }
    });

    setCategorizedChats({ today, yesterday, last7Days, last30Days, older });
  };

  const handleNewChat = async () => {
    setLoading(true);
    try {
      const user = getUserDetails();
      if (!user || !user.access_token) {
        throw new Error('User not authenticated');
      }

      const newChatData = {
        role: "user",
        content: "Bonjour",
      };
      
      const response = await API.post(URLS.CHATGPT.chat.createOrGetChat, newChatData, {
        headers: {
          Authorization: `Bearer ${user.access_token}`,
        },
      });

      const newChatId = response.data.chat_id;
      navigate(`/chat/${newChatId}`);
    } catch (error) {
      toast.error('Erreur lors de la création d\'une nouvelle discussion.');
    } finally {
      setLoading(false);
    }
  };

  const handleMenuVisibility = (chatId) => {
    setMenuVisibleChatId(chatId === menuVisibleChatId ? null : chatId);
  };


  const [activeChatId, setActiveChatId] = useState(null);

  // useEffect(() => {
//   const scrollToBottom = () => {
//     const messagesList = document.querySelector('.sidebar-content');
//     if (messagesList) {
//       messagesList.scrollTop = messagesList.scrollHeight;
//     }
//   };

//   scrollToBottom();
// }, [hoveredChatId]);



  return (
    <div className={`sidebar scroll-container ${isSidebarOpen ? 'open' : 'closed'}`}>
      <button className="toggle-btn" onClick={toggleSidebar}>
        {isSidebarOpen ? <FaTimes /> : <FaBars />}
      </button>
      {isSidebarOpen && (
        <>
          <div className="sidebar-header">
            
           
            <button onClick={toggleSidebar} className="close-sidebar-btn">
            <FiAlignRight />
            </button>
              <div className="">
              <h4 className='header-w'>Bienvenue {userName}! 😊</h4>
              </div>
              <button onClick={handleNewChat} className="new-chat-button">
              <FaPencilAlt />
            </button>
          </div>

          <div className="sidebar-content">
            {loading && (
              <div className="preloader-wrapper">
                <Preloader />
              </div>
            )}
            {['today', 'yesterday', 'last7Days', 'last30Days', 'older'].map((category, index) => (
              categorizedChats[category].length > 0 && (
                <div key={index}
                className="category-section"
                >
                  <h4>{category === 'today' ? 'Aujourd\'hui' : category === 'yesterday' ? 'Hier' : category === 'last7Days' ? '7 derniers jours' : category === 'last30Days' ? '30 derniers jours' : 'Plus anciens'}</h4>
                  {categorizedChats[category].map(chat => (
                    <div 
                      key={chat.id}
                      className={`chat-item-container ${location.pathname.includes(`/chat/${chat.id}`) ? 'hovered' : ''}`} // Ajoutez la classe conditionnelle ici
                      onMouseEnter={() => setHoveredChatId(chat.id)}
                      onMouseLeave={() => setHoveredChatId(null)}
                    >
                      <Link to={`/chat/${chat.id}`} className="chat-item">
                        {chat.messages.length > 0
                          ? chat.messages[0].content.substring(0, 30) +
                            (chat.messages[0].content.length > 30 ? '...' : '')
                          : 'Pas de messages'}
                      </Link>
                      {hoveredChatId === chat.id && (
                        <div className="menu-icon">
                          <FaEllipsisH onClick={() => handleMenuVisibility(chat.id)} />
                          {menuVisibleChatId === chat.id && (
                            <div className="chat-options-menu">
                              <button className="menu-item">
                                <FaArchive />
                              </button>
                              <button className="menu-item">
                                <FaShare />
                              </button>
                              <button
                                onClick={() =>
                                  confirmDeletion(URLS.CHATGPT.chat.delete(chat.id), t, () => setUserChats(userChats.filter(c => c.id !== chat.id)))
                                }
                                className="menu-item"
                              >
                                <LuTrash />
                              </button>
                            </div>
                          )}
                        </div>
                      )}
                    </div>
                  ))}
                </div>
              )
            ))}
          </div>

          <div className="sidebar-footer">
            <Link className="dropdown-item text-danger" to="#" onClick={() => logout(t)}>
            <FaSignOutAlt size={20} style={{ marginRight: '8px' }}/>

            Se Déconnecter
            </Link>
          </div>
        </>
      )}
    </div>
  );
};

export default Sidebar;
